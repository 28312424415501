import React, { useState } from "react";
import { FaTimes } from "react-icons/fa"; // Close icon


const TwilioModal = ({ show, onClose }) => {
  const [checked, setChecked] = useState(false);
  const [phone, setPhone] = useState("");

  if (!show) return null; // Modal hidden when `show` is false

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <img
          src="/logo.png"
          alt="Twilio Logo"
          className="twilio-logo mx-auto"
        />
        <h2 className="fs-3">Subscribe for Notifications</h2>
        <p>Receive notifications of your purchases
        </p>

        <div className="input-group">
          <input
            type="text"
            placeholder="Your phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <button>Join Now</button>
        </div>

        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <span className="checkmark"></span>
          I agree to receive notification messages from Into Words at the phone number provided above. I understand I will receive 4 messages a month, data rates may apply, reply STOP to opt out
        </label>

        <div className="privacy-links">
          <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms">Terms of Service</a>
        </div>
      </div>
    </div>
  );
};

export default TwilioModal;
