import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import Home from "./Home";
import Shop from "./Shop";
import Aboutus from "./Aboutus";
import Blog from "./Blog";
import Demo from "./Demo";
import Cross from "./components/Cross";
import Beg from "./components/Beg";
import Mid from "./components/Mid";
import TermsAndConditions from "./components/Terms";
import RefundPolicy from "./components/RefundPolicy";
import PrivacyPolicy from "./components/Privacy";
import ShopComp from "./components/ShopComp";
import PaymentPage from "./DigitalPayment";
import Physicalpayment from "./Physicalpayment";
import ThankYou from "./components/Thankyou";

function App() {
  const [playAudio, setPlayAudio] = useState(false);
  const location = useLocation(); // Get the current route

  // Disable audio on the About page
  const isAboutPage = location.pathname === "/about";

  return (
    <>
      {/* Global Audio Player (Hidden on About Page) */}
      {!isAboutPage && playAudio && (
        <ReactAudioPlayer
          src="https://intowordsgc.com/backend/public/storage/1.mp3"
          autoPlay
          loop
        />
      )}

      <Routes>
        <Route path="/" element={<Home setPlayAudio={setPlayAudio} />} />
        <Route path="/digitalcardpayment" element={<PaymentPage />} />
        <Route path="/physicalcardpayment" element={<Physicalpayment />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/crossroad" element={<Cross />} />
        <Route path="/begning" element={<Beg />} />
        <Route path="/card/:title" element={<ShopComp />} />
        <Route path="/view" element={<ShopComp />} />
        <Route path="/middle" element={<Mid />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
