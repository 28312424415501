import $ from 'jquery';
import 'jquery-scrollify';
import { useMediaQuery } from 'react-responsive';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TawkTo from '../TawkTo';
import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import { Helmet } from 'react-helmet';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Footer } from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

AOS.init();

const Main = () => {
    const navigate = useNavigate();
    const handleLinkClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };
    const [cards, setCards] = useState([]);
    const [beginning, setbeginning] = useState([]);
    const [middle, setMiddle] = useState([]);
    const [cross, setCross] = useState([]);
    // const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://intowordsgc.com/api/cards');
                console.log(response.data); // Log the API response
                setCards(response.data); // Set the response data directly to the cards array
                // Assume the response data already contains cards, middle, and cross as required
                setbeginning(response.data.filter(card => card.categories === 'beginning'));
                setMiddle(response.data.filter(card => card.categories === 'middle'));
                setCross(response.data.filter(card => card.categories === 'cross'));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    // const handleLinkClick = (path) => {
    //     navigate(path);
    //     window.scrollTo(0, 0);
    // };



    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    return (
        <>
            <Desktop>
                <div className='bg-dark' style={{ backgroundColor: "#111111", height: "auto" }}>

                    <figure>
                        <section class="section__first is--scrolled js--scrollify video-section" data-section-name="first">
                            {/* <video className="background-video" autoPlay muted loop>
                        <source src="/words.mp4" type="video/mp4" />

                    </video> */}
                            <img
                                id="second-img"
                                src="/banner.png"
                                className='w-100 h-100'
                            />
                            <div className="overlay">
                                <br />
                                <Header />
                                <br /><br /><br /><br />
                                <div className="middle-section">
                                    <h2>INTO WORDS</h2>
                                    <Link to="/shop"> <button className="button">Greeting Cards</button></Link>
                                </div>
                            </div>
                        </section>
                    </figure>
                    <figure>
                        <section className="section__first is--scrolled js--scrollify" data-section-name="second">
                            <div className="row" id="inner">


                                <div className="col-lg-12 d-flex justify-content-center mx-auto " data-aos="fade-up" data-aos-duration="2000" style={{ marginTop: "-30px" }}>
                                    <img
                                        id="first-img"
                                        className="d-flex justify-content-center mx-auto img "
                                        data-rotate-x="90deg"
                                        data-move-z="-500px"
                                        data-move-y="200px"
                                        src="/beg1.png"
                                    />


                                </div>
                                <div className="col-lg-4" data-aos="fade-up-right"
                                    data-aos-duration="2000">
                                    <img
                                        id="second-img"
                                        src="/beg5.png"
                                        style={{ width: "450px", marginTop: "-400px" }}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <div className="begin" data-aos="zoom-in-down" data-aos-duration="1000"
                                    >
                                        <h4 className="text-center">BEGINNING</h4>
                                        <Link to="/begning" onClick={() => handleLinkClick(`/begning`)} style={{ listStyle: "none", textDecoration: "none" }}>  <div className="d-flex align-items-center justify-content-center">
                                            <button className="shop mx-auto">Shop Now</button>
                                        </div></Link>
                                    </div>
                                    <br />
                                    <div className="desc" data-aos="fade-down" data-aos-duration="1000">
                                        <p className="para2">
                                            The point in time or space at which something starts. <br /> <br />
                                            Two people getting to know each other. The beginning stage: the couple can’t get enough of each other: they stay on the phone together all night, cannot wait to get in each other’s presence; flowers, butterflies, dates and dinners
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4" data-aos="fade-up-left"
                                    data-aos-duration="2000">
                                    <img
                                        id="third-img"
                                        src="/beg2.png"
                                        className="img mx-5"
                                        data-move-x="-500px"
                                        data-rotate="90deg"
                                        style={{ marginTop: "-100px" }}
                                    />
                                </div>
                            </div>

                        </section>

                    </figure>


                    <figure>
                        <section class="section__third is--scrolled js--scrollify js--third" data-section-name="third">
                            <div className='row'>
                                <div className='col-lg-4 d-flex jusfify-content-center mx-auto mt-5 ' data-aos="fade-up-left"
                                    data-aos-duration="2000" >
                                    <img className='d-flex jusfify-content-center img mx-auto' src='/mid1.png' />
                                </div>
                                <div className='col-lg-4 ' data-aos="fade-up" data-aos-duration="2000" style={{ marginTop: "-30px" }}>
                                    <img src='/mid3.png' className='img' style={{ marginLeft: "0px" }} />
                                </div>
                                <div className='col-lg-4  mt-5 ' data-aos="fade-up-right"
                                    data-aos-duration="2000">
                                    <img src='/mid6.png' className='img' />
                                </div>
                                <div className='col-lg-4 data-aos="fade-up-left"
                                    data-aos-duration="2000"' style={{ marginTop: "-40px" }}>
                                    <img src='/mid2.png' className='img' />
                                </div>


                                <div className='col-lg-4   '>
                                    <div className='begin ' data-aos="zoom-in-down" data-aos-duration="1000" style={{ marginTop: "-100px" }}>
                                        <h4 className='text-center'>Middle</h4>
                                        {/* <Link to="/middle" style={{ listStyle: "none", textDecoration: "none" }}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <button className='shop mx-auto'>Shop Now</button>
                                    </div>
                                </Link> */}
                                        <Link to="/middle" onClick={() => handleLinkClick(`/middle`)} style={{ listStyle: "none", textDecoration: "none" }}>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button className='shop mx-auto'>Shop Now</button>
                                            </div>
                                        </Link>
                                    </div>
                                    <br />
                                    <div className='desc ' data-aos="fade-down" data-aos-duration="1000" style={{ marginTop: "-10px" }}>

                                        <p className='para2'>The point or position at an equal distance from the sides, edges, or ends of something. <br /> <br />

                                            In the middle stage, the real person emerges.  The couple is in a committed relationship, yet there are disagreements and misunderstandings. They do not plan to end the relationship, but communicate and come to an agreement.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-4  ' data-aos="fade-up-right"
                                    data-aos-duration="2000" >
                                    <img src='/mid5.png' className='img' style={{
                                        marginTop: "-260px",
                                        height: "504px",
                                        marginLeft: "-202px"
                                    }} />
                                </div>
                            </div>
                        </section>
                    </figure>


                    <figure>
                        <section class="section__fourth is--scrolled js--scrollify" data-section-name="fourth">
                            <div className='row'>
                                {/* <div className='col-lg-4  '>
      <img src='/mid3.png' className='img'  />
  </div> */}
                                <div className='col-lg-4 d-flex jusfify-content-center mx-auto mt-5' data-aos="fade-up-left"
                                    data-aos-duration="2000">
                                    <img className='d-flex jusfify-content-center mx-auto img ' src='/cross1.png' />
                                </div>
                                <div className='col-lg-4' data-aos="fade-up" data-aos-duration="2000" style={{

                                    marginTop: "-30px"
                                }}>
                                    <img src='/cross4.png' className=' img  ' />
                                </div>
                                <div className='col-lg-4  ' data-aos="fade-up-right"
                                    data-aos-duration="2000"  >
                                    <img src='/cross3.png' className='img mt-5' />
                                </div>
                                <div className='col-lg-4  ' data-aos="fade-up-left"
                                    data-aos-duration="2000" >
                                    <img src='/cross2.png' className='img mx-5' style={{
                                        marginTop: "-254px",
                                        height: "504px",

                                    }} />
                                </div>

                                <div className='col-lg-4  ' style={{

                                    marginTop: "-0px"
                                }} >
                                    <div className='begins' data-aos="zoom-in-down" data-aos-duration="1000">
                                        <h4 className='text-center'>CROSSROAD</h4>
                                        <Link to="/crossroad" onClick={() => handleLinkClick(`/crossroad`)} style={{ listStyle: "none", textDecoration: "none" }}>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button className='shop mx-auto'>Shop Now</button>
                                            </div>
                                        </Link>
                                    </div>
                                    <br />   <br />
                                    <div className='desc' data-aos="fade-down" data-aos-duration="1000" style={{ marginTop: "-40px" }}>

                                        <p className='para2'>A crucial point especially where a decision must be made. <br /> <br />

                                            The couple is in a committed relationship and comes to a place that may be detrimental to their bond; infidelity, abuse, unexpected turns, lies, and dark secrets.
                                            The couple makes a decision to work things out or end the relationship.</p>
                                    </div>
                                </div>
                                <div className='col-lg-4  ' data-aos="fade-up-right"
                                    data-aos-duration="2000" style={{

                                        marginTop: "-50px"
                                    }} >
                                    <img src='/cross5.png' className='img ' />
                                </div>

                            </div>
                        </section>
                    </figure>
                </div>
                <TawkTo />
                {/* <Footer /> */}
            </Desktop>
            <Mobile>

            <div className="col-lg-4">  <br />
                        <div className="begin" data-aos="zoom-in-down" data-aos-duration="1000"
                        >
                            <h4 className="text-center">BEGINNING</h4>
                            <Link to="/begning" onClick={() => handleLinkClick(`/begning`)} style={{ listStyle: "none", textDecoration: "none" }}>  <div className="d-flex align-items-center justify-content-center">
                                <button className="shop mx-auto">Shop Now</button>
                            </div></Link>
                        </div>
                        <br />
                        <div className="desc" data-aos="fade-down" data-aos-duration="1000">
                            <p className="para2">
                                The point in time or space at which something starts. <br /> <br />
                                Two people getting to know each other. The beginning stage: the couple can’t get enough of each other: they stay on the phone together all night, cannot wait to get in each other’s presence; flowers, butterflies, dates and dinners
                            </p>
                        </div>
                    </div>
                <div className='container'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        style={{
                            '--swiper-navigation-color': '#fff',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                        }}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {beginning.length > 0 ? (
                            beginning.map((card, index) => (
                                <SwiperSlide key={card.id}>
                                    <div className='cards'>
                                        {/* Render image using base64 string */}
                                        <img src={`data:image/png;base64,${card.image}`} className="img2" alt={card.card_name} />
                                        <div className="d-flex align-items-center" style={{ position: "absolute", top: "90%" }}>
                                            <h5 className="text-light title text-start">{card.card_name}</h5>
                                        </div>
                                        <div className="info">
                                            {/* <p className="text-light">{card.price}</p> */}
                                            <Link
                                                to={`/card/${card.id}`}
                                                onClick={() => handleLinkClick(`/card/${card.id}`)}
                                                state={{ card }}
                                                className="button"
                                            >
                                                View Now
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <div>Loading...</div> // Loading state until data is fetched
                        )}

                    </Swiper>
                    {/* <div className="col-lg-12 d-flex justify-content-center mx-auto " data-aos="fade-up" data-aos-duration="2000" style={{ marginTop: "-30px" }}>
                                    <img
                                        id="first-img"
                                        className="d-flex justify-content-center mx-auto img animate__animated animate__flip"
                                        data-rotate-x="90deg"
                                        data-move-z="-500px"
                                        data-move-y="200px"
                                        src="/beg1.png"
                                    />


                                </div>
                                <div className="col-lg-4" data-aos="fade-up-right"
                                    data-aos-duration="2000">
                                    <img
                                        id="second-img"
                                        src="/beg5.png"
                                        style={{ width: "450px", marginTop: "-400px" }}
                                    />
                                </div> */}
                   
                </div>

                <div className='col-lg-4   '><br /><br /> <br /><br /> <br />
                        <div className='begin ' data-aos="zoom-in-down" data-aos-duration="1000" style={{ marginTop: "-100px" }}>
                            <h4 className='text-center'>Middle</h4>
                           
                            <Link to="/middle" onClick={() => handleLinkClick(`/middle`)} style={{ listStyle: "none", textDecoration: "none" }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button className='shop mx-auto'>Shop Now</button>
                                </div>
                            </Link>
                        </div>
                        <br />
                        <div className='desc ' data-aos="fade-down" data-aos-duration="1000" style={{ marginTop: "-10px" }}>

                            <p className='para2'>The point or position at an equal distance from the sides, edges, or ends of something. <br /> <br />

                                In the middle stage, the real person emerges.  The couple is in a committed relationship, yet there are disagreements and misunderstandings. They do not plan to end the relationship, but communicate and come to an agreement.
                            </p>
                        </div>
                    </div>
                <div className='container'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        style={{
                            '--swiper-navigation-color': '#fff',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                        }}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {middle.length > 0 ? (
                            middle.map((card, index) => (
                                <SwiperSlide key={card.id}>
                                    <div className='cards'>
                                        <img src={`data:image/png;base64,${card.image}`} className="img2" alt={card.card_name} />
                                        <div className="d-flex align-items-center" style={{ position: "absolute", top: "90%" }}>
                                            <h5 className="text-light title text-start">{card.card_name}</h5>
                                        </div>
                                        <div className="info">
                                            {/* <p className="text-light">{card.price}</p> */}
                                            <Link
                                                to={`/card/${card.id}`}
                                                onClick={() => handleLinkClick(`/card/${card.id}`)}
                                                state={{ card }}
                                                className="button"
                                            >
                                                View Now
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Swiper>
                   
                </div>

                {/* Render for 'Crossroad' */}
                <div className='col-lg-4  ' style={{

marginTop: "-0px"
}} >
<div className='begins' data-aos="zoom-in-down" data-aos-duration="1000">
    <h4 className='text-center'>CROSSROAD</h4>
    <Link to="/crossroad" onClick={() => handleLinkClick(`/crossroad`)} style={{ listStyle: "none", textDecoration: "none" }}>
        <div className="d-flex align-items-center justify-content-center">
            <button className='shop mx-auto'>Shop Now</button>
        </div>
    </Link>
</div>
<br />   <br />
<div className='desc' data-aos="fade-down" data-aos-duration="1000" style={{ marginTop: "-40px" }}>

    <p className='para2'>A crucial point especially where a decision must be made. <br /> <br />

        The couple is in a committed relationship and comes to a place that may be detrimental to their bond; infidelity, abuse, unexpected turns, lies, and dark secrets.
        The couple makes a decision to work things out or end the relationship.</p>
</div>
</div>
                <div className='container'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        style={{
                            '--swiper-navigation-color': '#fff',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                                price: "$6.00",
                                note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                            },
                        }}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {cross.length > 0 ? (
                            cross.map((card, index) => (
                                <SwiperSlide key={card.id}>
                                    <div className='cards'>
                                        <div className='cardimg'>
                                            <img src={`data:image/png;base64,${card.image}`} className="img2" alt={card.card_name} />
                                        </div>
                                        <div className="d-flex align-items-center"
                                            style={{ position: "absolute", top: "90%" }}>
                                            <h5 className="text-light title text-start">
                                                {card.card_name}</h5>
                                        </div>
                                        <div className="info">
                                            {/* <p className="text-light">{card.price}</p> */}
                                            <Link
                                                to={`/card/${card.id}`}
                                                onClick={() => handleLinkClick(`/card/${card.id}`)}
                                                state={{ card }}
                                                className="button"
                                            >
                                                View Now
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Swiper>
                  
                </div>
                <Footer />
            </Mobile>
        </>
    );
};

export default Main;