import React, { useEffect, useState } from 'react'
import Header from './components/Header/Header'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import { Helmet } from 'react-helmet';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Footer } from './components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Shop = () => {
    const [cards, setCards] = useState([]);
    const [beginning, setbeginning] = useState([]);
    const [middle, setMiddle] = useState([]);
    const [cross, setCross] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://intowordsgc.com/api/cards');
                console.log(response.data); // Log the API response
                setCards(response.data); // Set the response data directly to the cards array
                // Assume the response data already contains cards, middle, and cross as required
                setbeginning(response.data.filter(card => card.categories === 'beginning'));
                setMiddle(response.data.filter(card => card.categories === 'middle'));
                setCross(response.data.filter(card => card.categories === 'cross'));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleLinkClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <div style={{ background: "#000" }}>
            <Helmet>
                <title>Into Words - Shop</title>
            </Helmet>
            <Header />
            <div className='beg'>
                <br />
                <h2>SHOP US NOW</h2>
                <h5>Greeting Cards</h5>
                <h3>BEGINNING</h3>
            </div>

            {/* Render cards */}
            <div className='container'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    style={{
                        '--swiper-navigation-color': '#fff',
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                >
                    {beginning.length > 0 ? (
                        beginning.map((card, index) => (
                            <SwiperSlide key={card.id}>
                                <div className='cards'>
                                    {/* Render image using base64 string */}
                                    <img src={`data:image/png;base64,${card.image}`} className="img2" alt={card.card_name} />
                                    <div className="d-flex align-items-center" style={{ position: "absolute", top: "90%" }}>
                                        <h5 className="text-light title text-start">{card.card_name}</h5>
                                    </div>
                                    <div className="info">
                                        {/* <p className="text-light">{card.price}</p> */}
                                        <Link
                                            to={`/card/${card.id}`}
                                            onClick={() => handleLinkClick(`/card/${card.id}`)}
                                            state={{ card }}
                                            className="button"
                                        >
                                            View Now
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <div>Loading...</div> // Loading state until data is fetched
                    )}
                </Swiper>
            </div>

            {/* Render middle and cross sections similarly */}
            {/* Render for 'Middle' */}
            <div className='beg'>
                <br />
                <h3>Middle</h3>
            </div>
            <div className='container'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    style={{
                        '--swiper-navigation-color': '#fff',
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                >
                    {middle.length > 0 ? (
                        middle.map((card, index) => (
                            <SwiperSlide key={card.id}>
                                <div className='cards'>
                                    <img src={`data:image/png;base64,${card.image}`} className="img2" alt={card.card_name} />
                                    <div className="d-flex align-items-center" style={{ position: "absolute", top: "90%" }}>
                                        <h5 className="text-light title text-start">{card.card_name}</h5>
                                    </div>
                                    <div className="info">
                                        {/* <p className="text-light">{card.price}</p> */}
                                        <Link
                                            to={`/card/${card.id}`}
                                            onClick={() => handleLinkClick(`/card/${card.id}`)}
                                            state={{ card }}
                                            className="button"
                                        >
                                            View Now
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </Swiper>
            </div>

            {/* Render for 'Crossroad' */}
            <div className='beg'>
                <br />
                <h3>CROSSROAD</h3>
            </div>
            <div className='container'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    style={{
                        '--swiper-navigation-color': '#fff',
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                            price: "$6.00",
                            note: "Note: The description provided does not reflect the actual content of the card, it merely describes what is contained inside."
                        },
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                >
                    {cross.length > 0 ? (
                        cross.map((card, index) => (
                            <SwiperSlide key={card.id}>
                                <div className='cards'>
                                    <div className='cardimg'>
                                        <img src={`data:image/png;base64,${card.image}`} className="img2" alt={card.card_name} />
                                    </div>
                                    <div className="d-flex align-items-center"
                                        style={{ position: "absolute", top: "90%" }}>
                                        <h5 className="text-light title text-start">
                                            {card.card_name}</h5>
                                    </div>
                                    <div className="info">
                                        {/* <p className="text-light">{card.price}</p> */}
                                        <Link
                                            to={`/card/${card.id}`}
                                            onClick={() => handleLinkClick(`/card/${card.id}`)}
                                            state={{ card }}
                                            className="button"
                                        >
                                            View Now
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </Swiper>
            </div>

            <Footer />
        </div>
    );
};

export default Shop;
