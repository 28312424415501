import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import TawkTo from './TawkTo';
import { Footer } from './components/Footer';
import Main from './components/Main';
import Header from './components/Header/Header';

const Home = ({ setPlayAudio }) => {
    const [showPopup, setShowPopup] = useState(true);

    const handleAllowAudio = () => {
        setPlayAudio(true);
        setShowPopup(false);
    };

    const handleDenyAudio = () => {
        setPlayAudio(false);
        setShowPopup(false);
    };

    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 });
        return isDesktop ? children : null;
    };

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 });
        return isMobile ? children : null;
    };

    return (
        <>
            <Helmet>
                <title>Into Words - Home</title>
            </Helmet>

            <TawkTo />

            {showPopup && (
                <div style={popupStyle}>
                    <div style={popupContentStyle}>
                        <p>Do you want to enable background music?</p>
                        <button onClick={handleAllowAudio} style={allowButtonStyle}>Allow</button>
                        <button onClick={handleDenyAudio} style={denyButtonStyle}>Deny</button>
                    </div>
                </div>
            )}

            <Desktop>
                <div style={{ background: "#000" }}>
                    <Main />
                    <Footer />
                </div>
            </Desktop>

            <Mobile>
                <div className='bg-dark' style={{ backgroundColor: "#111111", height: "auto" }}>
                    <figure>
                        <section className="section__first is--scrolled js--scrollify video-section" data-section-name="first">
                            <img id="second-img" src="/banner2.png" className='w-100' />
                            <div className="overlay">
                                <br />
                                <Header />
                                <br /><br />
                                <div className="middle-section">
                                    <h2>INTO <br />WORDS</h2>
                                    <Link to="/shop"> <button className="button">Greeting Cards</button></Link>
                                </div>
                            </div>
                        </section>
                    </figure>
                    <Main />
                </div>
            </Mobile>
        </>
    );
};

// Popup styles
const popupStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999
};

const popupContentStyle = {
    background: "#fff",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center"
};

const allowButtonStyle = {
    background: "#28a745",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "10px"
};

const denyButtonStyle = {
    background: "#dc3545",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "10px"
};

export default Home;
