import { useState } from "react";
import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import TwilioModal from "./TwilioForm"; // Import modal

export const Footer = () => {
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const handleContactClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleLinkClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <div><br />
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <h2 className='text-light fw-bold word'>INTO WORDS</h2>
                        <div className='mainfooter align-items-center'>
                            <div className='col-lg-3 mx-2'>
                                <p>intowordscards@gmail.com</p>
                            </div>

                            {/* Center Section with Terms, Refund Policy, Privacy Policy */}
                            <div className='col-lg-6 text-center'>
                                <ul className="ul mt-3 d-flex justify-content-center">
                                    <li className='mx-2'><Link to="/terms" onClick={() => handleLinkClick("/terms")}>Terms & Conditions</Link></li>
                                    <li className='mx-2'><Link to="/refund-policy" onClick={() => handleLinkClick("/refund-policy")}>Refund Policy</Link></li>
                                    <li className='mx-2'><Link to="/privacy-policy" onClick={() => handleLinkClick("/privacy-policy")}>Privacy Policy</Link></li>
                                </ul>
                            </div>

                            <div className='col-lg-3 col-sm-12 text-start'>
                                <nav className="navbars">
                                    <ul className='ul mt-3'>
                                        <li className='mx-2'><Link to="/" onClick={() => handleLinkClick("/")}>Home</Link></li>
                                        <li className='mx-2'><Link to="/shop" onClick={() => handleLinkClick("/shop")}>Shop</Link></li>
                                        <li className='mx-2'><Link to="/about" onClick={() => handleLinkClick("/about")}>About</Link></li>
                                        <li className='mx-2'>
                                            <Link to="#contact" className='d-flex justify-content-center' onClick={handleContactClick}>Contact</Link>
                                        </li>
                                    </ul>
                                {/* <button onClick={handleContactClick} className="btn1">Subscribe</button> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* TwilioModal should be outside the footer so it covers the full screen */}
            <TwilioModal show={showPopup} onClose={handleClosePopup} />
        </div>
    );
};
